import { render, staticRenderFns } from "./XsscOrder.vue?vue&type=template&id=1db1af45&scoped=true&"
import script from "./XsscOrder.vue?vue&type=script&lang=js&"
export * from "./XsscOrder.vue?vue&type=script&lang=js&"
import style1 from "../../style.css?vue&type=style&index=1&id=1db1af45&prod&scoped=true&lang=css&"
import style2 from "./XsscOrder.vue?vue&type=style&index=2&id=1db1af45&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1db1af45",
  null
  
)

export default component.exports