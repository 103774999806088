<template>
  <div class="zt-page-content">
    <div class="frm flex" style="min-width:1500px;height:100%">
      <div class="flex" style="margin-right: 10px;">
        <div style="width: 300px;height:675px;position: relative;">
          <!-- <img src="../../../../assets/images/mp_bg.png" style="width:100%;height:100%;" />
          <iframe :src="shopUrl" scrolling="auto" class="mb-content"></iframe> -->
          <div class="wx_title">小程序店铺示例</div>
          <div style="width:100%;">
            <img src="../../../../assets/images/mp_bg1.png" style="width:100%;" />
          </div>
        </div>
        <div style="width: 220px;text-align: center;">
          <div class="wx_code flex flex-center">
              <!-- <img :src="qrcodeUrl"> -->
            <img style="position:relative;top:-20px
                    transform:scale(0.6);
                    -ms-transform:scale(0.6);
                    -moz-transform:scale(0.6);
                    -webkit-transform:scale(0.6);
                    -o-transform:scale(0.6);"
            :src="wx_code"/>
          </div>
          <div class="wx_text">
            <div>扫描二维码</div>
            <div>分享店铺给微信好友</div>
            <div>（每个账号分享的二维码都会不同）</div>
          </div>
          <div style="margin-top: 30px;">
            <div @click="goto('/factory/zysp/submit')" class="button active">新增上架产品</div>
            <!-- <div @click="refresh()" style="margin-top:12px;" class="button">刷新预览</div> -->
          </div>
        </div>
      </div>
      <div class="flex-1" style="margin-left: 10px;overflow: hidden;">
        <div class="tab_menu flex">
          <div
            v-for="(item,index) in tabList"
            :key="index"
            @click="tab(index)"
            class="menu_item"
            :class="{'cur':tabIndex == index}"
          >
            <div>{{item.name}}</div>
          </div>
        </div>
        <div>
          <Share v-if="tabIndex==0"></Share>
          <XsscOrder v-if="tabIndex==1"></XsscOrder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import QRCode from "qrcode";
import { getWxCode } from "@/service/mobile";
import { BASE_URL, IMG_URL_PRE } from "@/config";
import Share from "./Share.vue";
import XsscOrder from "./XsscOrder.vue";
export default {
  components: { Share, XsscOrder },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      shopUrl:
        BASE_URL +
        "/mobile/shop/" +
        this.$store.state.loginUser.comp.id +
        "/" +
        this.$store.state.loginUser.user.id,
      qrcodeUrl: null,
      wx_code: null,
      tabList: [{ name: "分享信息" }, { name: "询价单" }],
      tabIndex: 0,
      list: [],
      userInfo: null
    };
  },
  created() {
    this.userInfo = this.$store.state.loginUser;
    QRCode.toDataURL(this.shopUrl)
      .then(url => {
        this.qrcodeUrl = url;
      })
      .catch(err => {
        console.log(err);
        this.$message.error(err.message);
      });
    this.get_wx_code();
  },
  methods: {
    goto(path) {
        this.$router.push(path)
    },
    refresh() {
      this.$router.go(0);
    },
    // 选项卡
    tab(index) {
      this.tabIndex = index;
    },
    get_wx_code() {
      let obj = {
        page_uri: "",
        img_width: "140px",
        compid: this.userInfo.comp.id,
        suid: this.userInfo.user.id
      };
      getWxCode(obj)
        .then(rst => {
          // console.log("1111111", rst);
          this.wx_code = "data:image/png;base64," + rst.img;
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    }
  }
};
</script>
<style scoped>
.frm {
  overflow: auto;
  margin: 0 auto;
}
.frm > div {
  margin: 20px;
  background: rgba(255, 255, 255, 1);
  padding: 20px 20px 0 20px;
}
.mb-content {
  position: absolute;
  top: 80px;
  left: 16px;
  border: none;
  width: 295px;
  height: 513px;
  border: 1px solid #000;
}
.wx_title {
  text-align: center;
  padding:10px 0 30px 0;
  font-size:20px;
  font-weight: 600;
  color:#292929;
  line-height:28px;
}
.wx_code {
    width: 100%;
    height: 220px;
    overflow: hidden;
}
.wx_text {
  text-align: center;
  font-size: 12px;
  color: #4D4D4D;
  line-height: 24px;
}
.button {
    display: inline-block;
    width: 120px;
    text-align: center;
    height:40px;
    line-height: 40px;
    background:#fff;
    border: 1px solid rgba(131,98,228,1);
    box-shadow:0px 2px 4px 0px rgba(179,186,255,1);
    border-radius:20px;
    font-size: 14px;
    color: #5074EE;
    cursor: pointer;
}
.button.active {
  color: #fff;
  background:linear-gradient(90deg,rgba(131,98,228,1) 0%,rgba(80,116,238,1) 100%);
  box-shadow:0px 2px 4px 0px rgba(179,186,255,1);
}

/* 选项卡 */
.tab_menu {
  padding-bottom: 20px;
}
.tab_menu .menu_item > div {
  padding: 0 30px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #5074ee;
  border-radius: 25px;
  margin: 0 12px;
  cursor: pointer;
  font-size: 16px;
  color: #5074ee;
}
.tab_menu .menu_item > img {
  height: 50px;
}
.tab_menu .menu_item.cur > div {
  color: #fff;
  background: #5074ee;
  border-radius: 25px;
}
</style>